$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.LoginAndSignupPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;

  .Card {
    width: 350px;
    margin: 10px; }

  // small
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; } }
