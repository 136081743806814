$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.TagsInput {
  display: flex;
  align-items: center;
  @include inputBorder;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  min-height: 39px;
  &-disabled {
    border-color: var(--disabled-input-background);
    background-color: var(--disabled-input-background);
    cursor: not-allowed;
    input {
      display: none; }
    .TagsInput {
      &-tag {
        color: #606060;
        border-color: var(--disabled-input-background-dark);
        background-color: var(--disabled-input-background-dark); }
      &-deleteIcon {
        color: var(--disabled-input-background-darker);
        cursor: not-allowed; }
      &-addButton {
        display: none; } } }
  &-unsaved {
    &, &[disabled] {
      border-color: var(--unsaved-border-color);
      background-color: var(--unsaved-background-color); }
    .TextInput input::placeholder {
      color: #5a5e66; } }
  &-tags {
    display: flex;
    flex-wrap: wrap; }
  &-tag {
    border: 1px solid #d1d3dd;
    border-radius: 10px;
    color: #606060;
    padding: 3px 5px 3px 10px;
    margin: 5px;
    background-color: var(--background-grey); }
  &-deleteIcon {
    cursor: pointer;
    margin-left: 5px; }
  &-addIcon {
    color: var(--success-button-background-color); }
  .TextInput {
    flex-grow: 1;
    border: none;
    &:focus-within {
      @include undoInputFocusBorder; } }
  &-addButton {
    font-weight: bold;
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
    font-size: 15px;
    &:hover {
      color: var(--tru-blue); } } }
