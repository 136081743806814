$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.SwitchListMember {
  padding: 10px;
  margin: 0 -10px;
  &:hover, &:focus-within {
    background-color: #f7f7f7; }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .Switch {
      margin-left: auto; } }

  &-toggleDescription {
    > span {
      position: static; }
    transition: transform var(--collapse-animation-duration); }
  &-descriptionOpen &-toggleDescription {
    transform: rotate(90deg); }

  &-description {
    padding: 0.5em 70px 0 0;
    color: var(--greyed-out);
    font: var(--default-font); }

  &-toggleDescriptionButton {
    display: flex;
    align-items: center;
    .Icon {
      margin-left: calc(var(--horizontal-space) / 2); } }

  &-unsaved {
    background-color: var(--unsaved-background-color);
    &:hover, &:focus-within {
      background-color: var(--unsaved-background-color-darkened); } } }
