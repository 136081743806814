$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.PageOfEndUsers {

  &-table {
    margin-bottom: 10px;
    border: 1px solid var(--border-grey);
    > .Table, > .Loading {
      height: 337px; }
    > .Loading {
      margin: 0; }

    .Table {
      *[x-column="Created At"],
      *[x-column="Used At"], {
        min-width: 119px; } } }

  &-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include onScreensNarrowerThan('desktop') {
      flex-direction: column-reverse;
      align-items: flex-start;
      .PageOfEndUsers-downloadCsv {
        margin-bottom: 5px; } } }

  &-NewFilterControl {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      margin-top: 5px; }
    @include onScreensWiderThan('tablet') {
      justify-content: flex-start;
      align-items: center;
      > .Dropdown {
        margin-right: 1em; } }
    @include onScreensNarrowerThan('tablet') {
      flex-direction: column;
      align-items: flex-start; }
    .HelpButton {
      margin-left: 5px; } }

  &-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 3px -2px; }

  &-Filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0.5em 5px 1em;
    margin: 2px;
    border: 1px solid #d7ddff;
    border-radius: 5px;
    background-color: aliceblue;
    &-description {
      margin-right: 5px; }
    &-value {
      background-color: var(--background-grey);
      padding: 2px 5px;
      white-space: pre-line;
      border-radius: 5px;
      white-space: nowrap;
      text-overflow: elipse; } } }
