$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-decoration: none;
  -webkit-appearance: none;
  text-align: inherit;
  user-select: none;
  align-items: flex-start;
  letter-spacing: normal;
  line-height: initial;
  overflow-x: visible;
  overflow-y: visible;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
  -webkit-border-image: none;
  font: var(--default-font);
  font-family: var(--default-font-family);
  color: var(--default-font-color);
  &, &:focus {
    outline: none; }

  &[disabled] {
    cursor: not-allowed;
 }    // border: none

  &-none {
    background: transparent;
    color: inherit;
    margin: 0;
    padding: 0;
    &, &:focus-within {
      outline: none;
      border: none;
      box-shadow: none; }
    &[disabled] {
      opacity: 0.3; } }

  &-subtle, &-normal, &-primary, &-success, &-transparent {
    display: inline-block;
    white-space: nowrap;
    font-weight: 500;
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px; }

  &-subtle, &-normal, &-primary, &-success, &-transparent, &-link {
    @include delayInputFocusBorder;
    &:focus {
      @include inputFocusBorder; } }

  --transparent-button-text-color: var(--default-font-color);
  --transparent-button-border-color: transparent;
  --transparent-button-border-color-lightened: transparent;
  --transparent-button-background-color: transparent;
  --transparent-button-background-color-lightened: transparent;
  --transparent-button-background-color-darkened: transparent;
  --transparent-button-hover-background-color: transparent;
  --transparent-button-disabled-text-color: transparent;
  --transparent-button-disabled-background-color: transparent;

  @each $type in 'subtle', 'normal', 'primary', 'success', 'transparent' {
    &-#{$type} {
      color: var(--#{$type}-button-text-color);
      background-color: var(--#{$type}-button-background-color);
      border: 1px solid var(--#{$type}-button-border-color);
      &:hover {
        background-color: var(--#{$type}-button-hover-background-color);
        border-color: var(--#{$type}-button-hover-border-color); }
      &[disabled] {
        &, &:hover {
          color: var(--#{$type}-button-disabled-text-color);
          background-color: var(--#{$type}-button-disabled-background-color);
          border-color: var(--#{$type}-button-disabled-border-color); } } } }

  &-fat {
    padding: 7px 14px;
    font-size: 16px; }

  &-block {
    display: block;
    width: 100%; }

  &-link {
    background: transparent;
    border: none;
    color: inherit;
    margin: 0;
    padding: 0;
    color: var(--dark-navy-blue);
    text-decoration: underline; } }
