$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BobInviteEndUsers {
  .Layout-maincontent {
    background-color: white;
    padding: 20px; }

  .BobInviteEndUserForm {
    margin-right: 20px; }

  &-inviteTools {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin: 10px 0 20px 0;
    flex-direction: column;
    .BobInviteEndUserForm {
      margin: 0 0 20px 0; } }

  &-table {
    margin: 10px 0;
    border: 1px solid var(--border-grey);
    > .Table, > .Loading {
      height: 337px; }
    > .Loading {
      margin: 0; } }

  &-InviteActions {
    &-hiddenInput {
      position: fixed;
      top: -999em;
      left: -999em; } }

  &-inviteUsersHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .Link {
      margin-left: 7px;
      font-size: 11px;
      color: var(--greyed-out-darkened);
      font-style: italic; } } }
