$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.TextInputWithIcon {
  @include inputBorder;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  &-disabled {
    border-color: var(--disabled-input-background);
    background-color: var(--disabled-input-background);
    cursor: not-allowed;
    .TextInputWithIcon-icon {
      opacity: .4; } }
  &-icon {
    flex: 0 0 40px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center; }


  .Dropdown-select {
    background: transparent; }
  .TextInput, .Dropdown {
    @include undoInputBorder; }
  .TextInput {
    flex: 1 1;
    border: none;
    background: none; }
  &-left {
    .TextInputWithIcon-icon {
      border-right: 1px solid var(--border-grey); } }
  &-right {
    .TextInputWithIcon-icon {
      border-left: 1px solid var(--border-grey); } } }
