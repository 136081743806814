$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BobSignupWizard {
  &-accordion {
    border-top: none; }
  &-success {
    text-align: center; }

  &-NewPublishStep {
    padding-top: 100px; }

  .Layout-maincontent > .OneColumn {
    padding: 0;
    border: 0;
    background-color: transparent;
    padding-bottom: calc(100vh - var(--top-nav-height) - 61px);
    > .Accordion {
      @media only screen and (max-width: 1140px) {
        border-right: none;
        border-left: none; } } }

  &-SideNav {
    .SideNav-content {
      display: flex;
      flex-direction: column; }
    &-Button {
      position: relative;
      white-space: normal;
      display: flex;
      align-items: flex-start;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      &-topline,
      &-bottomline {
        stroke: white;
        stroke-width: 2; }
      &-circle,
      &-completed-circle {
        stroke: white;
        stroke-width: 2;
        fill: var(--side-nav-background);
        fill: transparent; }
      > svg {
        position: absolute;
        top: 0;
        left: 10px; }
      > span {
        flex: 1 1;
        display: block;
        padding-left: 40px;
        padding-top: 0;
        padding-bottom: 20px; } } } }
