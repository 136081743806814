$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.WYSIWYGContent {
  overflow: hidden;
  figure.media iframe {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 600px;
    // vimeo
    background: black;
    width: 100%;
    min-height: 465px;
    @include onScreensNarrowerThan('desktop') {
      min-height: 43vw; }
    @include onScreensNarrowerThan('tablet') {
      min-height: 48vw; } } }


// this global variable is a special case
.ck-content {
  a[href] {
    color: var(--dark-navy-blue);
    &, &:focus {
      outline: none; } }

  .image > figcaption {
    background: transparent;
    a[href] {
      color: var(--default-font-color); } } }
