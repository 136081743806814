$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.CropImageInput {
  position: relative;
  display: inline-block;
  max-width: 100%;

  &-fileInput {
    opacity: 0;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed; } }

  &-fileInput {
    @include fillPositionedParent; }

  border: 1px solid transparent;
  &:not(&-cropping) {
    @include inputBorder;
    @include delayInputFocusBorder;
    border-color: transparent;
    &:focus-within {
      @include inputFocusBorder; } }

  &-image {
    max-width: 100%;
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; }

  &-error {
    color: red; }

  &:hover, &:focus, &:focus-within {
    > .Overlay-hidden {
      opacity: 1; } }

  &-unsaved:not(&-circle),
  &-unsaved .CropImageInput-image {
    border-color: var(--unsaved-border-color);
    box-shadow: 0 0 0 2px var(--unsaved-border-color); }

  > .ButtonRow {
    position: absolute;
    right: 0;
    bottom: -35px; }

  &-circle,
  &-circle &-image,
  &-circle .Overlay {
    border-radius: 50%; }
  &-cropping,
  &-cropping .Overlay {
    border-radius: 0; }

  &-cropping {
    z-index: 1001;
    @include onScreensNarrowerThan('phone') {
      &, .Button {
        box-shadow: 0 0 12px 4px rgba(0,0,0,.5); } } }

  &-Shroud {
    @include shroud;
    transition: opacity 200ms ease-in-out;
    @include onScreensNarrowerThan('phone') {
      display: none; }
    &-animation {
      &-enter {
        opacity: 0; }
      &-enter-active {
        opacity: 1; }
      &-exit {
        opacity: 1; }
      &-exit-active {
        opacity: 0; } } }

  &-disabled {
    cursor: not-allowed; } }
