$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.PhoneAndCountryInput {
  @include inputBorder;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  > .TextInput, > .Dropdown {
    @include undoInputBorder;
    border-radius: 0; }
  > .TextInput {
    flex: 1 1; }
  &-CountrySelect {
    position: relative;
    border-right: 1px solid var(--border-grey);
    &-value {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1em; }
    > select {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      border: none;
      background-color: #fff;
      &:focus {
        outline: none; } }
    &-disabled {
      border-color: var(--disabled-input-background);
      background-color: var(--disabled-input-background);
      color: #606060;
      -webkit-text-fill-color: #606060; } } }
