$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Header {
  -webkit-appearance: none;
  border: 0px transparent;
  padding: 0;
  background-color: transparent;
  color: var(--header-font-color);
  &, &:focus {
    outline: none; }
  &-xs {
    font-size: var(--xs-header-font-size);
    font-weight: var(--xs-header-font-weight); }
  &-sm {
    font-size: var(--sm-header-font-size);
    font-weight: var(--sm-header-font-weight); }
  &-md {
    font-size: var(--md-header-font-size);
    font-weight: var(--md-header-font-weight); }
  &-lg {
    font-size: var(--lg-header-font-size);
    font-weight: var(--lg-header-font-weight); }
  &-xl {
    font-size: var(--xl-header-font-size);
    font-weight: var(--xl-header-font-weight); }
  &-xxl {
    font-size: var(--xxl-header-font-size);
    font-weight: var(--xxl-header-font-weight); }
  &-underlined {
    border-bottom: 1px solid var(--border-grey-lightened);
    padding-bottom: 5px;
    margin-bottom: 15px; }
  &-bold {
    font-weight: bold; }
  &-italic {
    font-style: italic; }
  &-centered {
    text-align: center; }
  &-subtle {
    color: var(--grey-text); } }
