$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.CategorizedConsentsList {
  display: flex;
  flex-direction: column;
  &-formalDescriptionModal {
    max-width: 400px;
    .Header {
      text-align: center;
      margin-bottom: 10px; }
    .Button {
      display: block;
      margin: auto;
      margin-top: 15px; } }
  .Icon {
    cursor: pointer; }
  &-row {
    &-header {
      border-bottom: 1px solid var(--border-grey);
      padding-bottom: 2px;
      margin-bottom: 5px;
      color: var(--grey-text); } } }
