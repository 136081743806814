$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Switch {
  display: inline-flex;
  position: relative;
  width: 44px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
  transition: opacity 300ms;

  &-pseudo {
    width: 40px;
    height: 20px;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: background-color 300ms;
    background-color: rgb(221, 221, 221);
    &, &:focus {
      outline: none; } }

  &-ball {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 100%;
    transition: left 300ms;
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-image: linear-gradient(rgb(195, 195, 195) 0%, rgb(255, 255, 255) 100%);
      background-color: #f8f8f8;
      background-size: 100% 100%; } }

  &:focus-within &-pseudo {
    @include inputFocusBorder; }

  &-checked &-pseudo {
    background-color: var(--tru-blue); }
  &-checked &-ball {
    left: 22px; }

  &-saving &-ball {
    left: 11px; }

  &[disabled] {
    opacity: .6;
    .Button, .Button[disabled] {
      opacity: 1;
      cursor: not-allowed; } }

  &-unsaved .Switch-pseudo {
    background-color: var(--unsaved-border-color);
    &[disabled] {
      background-color: var(--unsaved-and-disabled-border-color); } } }
