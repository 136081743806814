$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationProfileForm {
  &-topfields {
    @include onScreensNarrowerThan('phone') {
      display: block; }
    @include onScreensWiderThan('phone') {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; } }
  &-iconUploader {
    display: flex;
    align-items: center;
    &-button {
      margin-left: 10px; } }
  &-icons {
    @include onScreensNarrowerThan('phone') {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      > .Form-Item:first-child {
        margin-right: 1em; } }
    @include onScreensWiderThan('phone') {
      flex: 0 0;
      margin-right: 10px; } }

  &-firstThreeFields {
    flex: 1 1; }

  &-dpoEmail {
    width: 100%;
    margin-top: 1px;
    .Dropdown-select {
      width: 100%;
      height: 35px; } }
  &-dpoLabel {
    display: flex;
    justify-content: space-between; }

  .Form-ButtonRow {
    margin-top: 20px; }

  .Button-back {
    margin-bottom: -10px; }

  &-saveRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 15px;
    .Button:not(:last-child) {
      margin-right: 1em; } }

  &-SisaModal {
    max-width: 650px;
    white-space: pre-line;
    &-textContent {
      white-space: pre-line; }
    &-continueButton {
      display: flex;
      justify-content: center; }
    &-header {
      text-align: center;
      @include onScreensNarrowerThan('phone') {
        font-size: 1.11em; }
      &-notice {
        margin-top: -10px; } } }

  textarea[name="mailingstreet"] {
    height: 87px; }
  textarea[name="description"] {
    height: 112px; } }
