$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Layout {
  --top-nav-height: 58px;
  --main-content-width: 960px;
  --right-content-width: 340px;

  min-height: 100vh;
  padding-top: var(--top-nav-height);
  padding-left: var(--side-nav-width);
  @include onScreensNarrowerThan('tablet') {
    padding-left: 0; }

  &-leftColumn {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: var(--side-nav-width);
    overflow: auto;
    @include onScreensNarrowerThan('tablet') {
      display: block;
      top: var(--top-nav-height);
      left: calc( var(--side-nav-width) * -1 );
      bottom: 0;
      height: auto;
      transition: left 100ms;
      background: var(--side-nav-background);
      &-mobileMenuVisible {
        z-index: 1000;
        left: 0; }
      .SideNav-Header {
        font-size: 1em; }
      .SideNav {
        font-size: 1.2em; } } }

  &-rightColumn {
    width: var(--right-content-width);
    margin-left: 20px;
    > *:first-child {
      height: 100vh;
      width: var(--right-content-width);
      overflow: hidden;
      padding: 20px 0;
      position: fixed;
      display: flex;
      flex-direction: column;
      @include onScreensNarrowerThan('desktop') {
        height: calc(100vh - var(--top-nav-height)); } } }

  &-topnav {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    height: calc( var(--top-nav-height) );
    left: var(--side-nav-width);
    background: var(--top-nav-background);
    font-size: 80%;
    z-index: 10;
    font-weight: 500;
    padding: 20px 45px 23px 45px;
    @include onScreensNarrowerThan('tablet') {
      left: 0;
      padding-left: 20px;
      padding-right: 20px; }

    &-logoutText {
      margin-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 10px; }
    &-logoutText, .Link-text, .Icon {
      @include onScreensNarrowerThan('tablet') {
        color: white; } }
    .BreadCrumbs {
      white-space: nowrap;
      text-overflow: ellipsis;
      @include onScreensNarrowerThan('tablet') {
        display: none; } } }

  &-maincontent {
    min-height: calc(100vh - var(--top-nav-height));
    position: relative;
    &, & > .OneColumn {
      background: var(--background-grey); } }

  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: black;
    opacity: .6;
    @include onScreensWiderThan('desktop') {
      display: none; } } }
