$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OneColumn {
  padding: 20px 45px;
  position: relative;
  background-color: white;
  max-width: var(--main-content-width);
  min-height: calc(100vh - var(--top-nav-height));
  @include onScreensNarrowerThan('tablet') {
    padding: 20px 20px; }

  &-noPadding {
    padding: 0; } }
