$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.StyleishModal {
  &-scrollInternally {
    overflow: hidden; }
  &-scrollInternally &-window > .Modal-window-body {
    max-height: calc(100vh - 40px);
    @include onScreensNarrowerThan('phone') {
      max-height: calc(100vh - 140px); } }
  &-scrollInternally &-body {
    overflow: auto;
    overscroll-behavior: contain; }

  &-window {
    min-height: auto;
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(0, 0, 0, 0.65);
    background-color: transparent;
    @include onScreensNarrowerThan('phone') {
      max-height: calc(100vh - 140px); }
    > .Modal-window-body {
      display: flex;
      flex-direction: column; } }

  &-title {
    flex-shrink: 0;
    min-height: 30px;
    padding: 10px 40px;
    background-color: var(--navy-blue);
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include onScreensNarrowerThan('phablet') {
      font-size: var(--md-header-font-size); }
    @include onScreensNarrowerThan('phone') {
      font-size: inherit;
      font-weight: bolder; } }

  &-body {
    flex: 2 2;
    background-color: white;
    @include removeVerticalMarginFromChildren;
    @include onScreensWiderThan('tablet') {
      max-width: calc(var(--page-column-width) * 0.61803); } }


  &-window-padded &-body {
    padding: var(--vertical-padding) var(--horizontal-padding); }


  &-window, &-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  &-window, &-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }

  .Modal-closeButton {
    color: white;
    opacity: 0.75; }

  &-animation {
    &-enter {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
      .StyleishModal-window {
        transform: scale(0.90);
        transition: transform 200ms ease-out 50ms; } }
    &-enter-active {
      opacity: 1;
      .StyleishModal-window {
        transform: scale(1); } }
    &-exit {
      opacity: 1;
      transition: opacity 200ms ease-in-out 100ms;
      .StyleishModal-window {
        transform: scale(1);
        opacity: 1;
        transition: transform 200ms ease-out, opacity 200ms; } }
    &-exit-active {
      opacity: 0;
      .StyleishModal-window {
        opacity: 0;
        transform: scale(0.90); } } } }
