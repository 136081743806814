$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BobEmailList {
  &-emails {
    margin-top: 5px;
    padding: 16px;
    border: 1px solid var(--border-grey);
    border-radius: 4px;
    &-email:not(:last-child) {
      margin-bottom: 12px; }
    &-email {
      &-resendButton {
        font-style: italic;
        color: var(--navy-blue);
        font-size: 12px; }
      &-label {
        margin: 0 10px;
        font-size: 12px;
        border-radius: 2px;
        padding: 3px 4px;
        &-dpo {
          font-weight: bold;
          color: white;
          background-color: #28a745; }
        &-unverified {
          color: #586069;
          background-color: #eaecef; } } } } }
