$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Tooltip {
  &-Bubble {
    pointer-events: none;
    position: fixed;
    z-index: 9999;
    background: var(--boring-blue);
    color: white;
    @include contentBoxBorder;
    padding: 5px 10px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 150ms;
    letter-spacing: 1px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif; }
  &-visible {
    opacity: 1;
    transition: opacity 100ms; } }
