$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BobAddEmailForm {
  margin: 10px 0;
  .Alert {
    margin-bottom: 10px; }
  .Header {
    margin-bottom: 5px; }
  &-wrapper {
    display: flex;
    flex-direction: row;
    .TextInput {
      flex: 1 1 auto;
      margin-right: 10px; } } }
