$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Paginator {
  display: flex;
  flex-direction: row;
  .Button {
    margin-right: 3px;
    @include onScreensNarrowerThan('phone') {
      padding: 12px; } }
  &-pages {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: center; } }
