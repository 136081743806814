$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Card {
  position: relative;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
  border: 1px solid #e6ebf5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  color: #2d2f33;
  border-radius: 4px;
  width: fit-content;
  max-width: var(--content-column-width);
  margin-left: auto;
  margin-right: auto;

  &-Header {
    text-align: left;
    padding: 10px 20px;
    border-bottom: 1px solid #e6ebf5;
    font-weight: 700;

    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 2;

    &:last-child {
      border-bottom: none; }

    &-centered {
      justify-content: center; }

    &-Title {
      flex: 1 1 100%; }

    &-spacer {
      flex: 3 3 100%; } }

  &-Body {
    padding: 10px 20px; }

  &-Buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    > * {
      margin-left: 5px; } } }
