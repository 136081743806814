$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BreadCrumbs {
  overflow-y: hidden;
  overflow-x: auto;
  --BreadCrumbs-background-background-color: var(--border-grey);
  > div {
    min-width: 100%;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-end;
    background-color: #f5f5f5; }

  &-Crumb {
    --half-height: 25px;
    --height: calc(var(--half-height) * 2);
    --background-color: #e6e9ec;
    &:hover {
      --background-color: var(--tru-blue);
      color: white; }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: var(--height);
    line-height: var(--height);
    text-align: center;
    margin-left: calc(0px - var(--half-height));
    max-width: none;

    &:last-child { // first-child
      margin-left: 0;
      > *:nth-child(1) {
        padding-left: var(--horizontal-padding); } }

    &:first-child { // last-child
      > *:nth-child(1) {
        box-shadow: none; } }

    pointer-events: none;
    > * {
      pointer-events: all; }

    > *:nth-child(1) {
      background-color: var(--background-color);
      padding-left: calc(var(--horizontal-padding) + 25px);
      box-shadow: 0px 0px 50px 30px var(--BreadCrumbs-background-background-color); }

    > *:nth-child(2) {
      border: var(--half-height) solid transparent;
      border-right-width: 0px;
      border-left: var(--half-height) solid var(--background-color);
      width: 0px; } }

  &-transition {
    &-enter {
      max-width: 0px; }
    &-enter-active {
      max-width: var(--client-width); }
    &-exit {
      max-width: var(--client-width); }
    &-exit-active {
      max-width: 0px; }
    &-enter-active,
    &-exit-active {
      transition: max-width 200ms ease-in-out; } } }
