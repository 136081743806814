$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Accordion {
  $border: 1px solid var(--border-grey);
  border-left: $border;
  border-right: $border;
  border-top: $border;
  &-Section {
    &-title {
      display: flex;
      align-items: center;
      width: 100%; // because <button/>
      text-align: left;
      line-height: 2.75;
      padding: 0 1em;
      font-weight: 700;
      background-color: white;
      border-bottom: $border;
      > span {
        flex: 1 1; } }
    &-content {
      overflow: auto;
      box-shadow: inset 0 0 7px 2px var(--border-grey);
      max-height: 0;
      background-color: white;
      > div {
        padding: 10px 20px 20px 20px; } }

    &:last-child {
      .Accordion-Section-content {
        border-bottom: $border; } }

    &-disabled {
      opacity: 0.5; }

    &-animating {
      .Accordion-Section-content {
        pointer-events: none;
        overflow: hidden;
        transition-property: max-height;
        transition-duration: 500ms;
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition-delay: 0s; } }

    &-open {
      .Accordion-Section-content {
        max-height: initial; } }
    &:last-child {
      .Accordion-Section-title {
        border-bottom: none; } } } }
