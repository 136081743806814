$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
$circle-length: 151px;
$check-length: 36px;

.SuccessAnimation {
  animation: 1s ease-out 0s 1 both scaleAnimation;
  &-result {
    fill: green;
    opacity: 0;
    animation: 0.3s linear 0.9s both fadeIn; }
  &-circle {
    stroke-dasharray: $circle-length $circle-length;
    stroke: green;
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut; }
  &-check {
    stroke-dasharray: $check-length $check-length;
    stroke: green;
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut; } }


@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }
