$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Overlay {
  @include fillPositionedParent;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  background-color: rgba(211,211,211, 0.5);
  box-shadow: inset 0 0 1px 1px black;
  &, > .Icon {
    color: white;
    text-shadow: 0 0 10px black; }
  > .Icon {

    font-size: 50px; }

  &-small {
    font-size: 15px;
    > .Icon {
      font-size: 25px;
      margin-bottom: -5px; } }

  &-hidden {
    transition: opacity 200ms;
    opacity: 0;
    // Note: expected usage:
    // .ParentComponent
    //    &:hover, &:focus, &:focus-within
 } }    //      .Overlay-hidden{ opacity: 1 }
