$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.TextInput {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  @include inputBorder;
  box-sizing: border-box;
  color: #5a5e66;
  input {
    border: none;
    background: transparent;
    height: 35px;
    line-height: normal;
    color: #5a5e66;
    padding: 0 10px;
    outline: none;
    box-shadow: none;
    display: block;
    width: 100%;
    &::placeholder {
      color: #b4bccc; }
    &:-webkit-autofill {
      -webkit-text-fill-color: #5a5e66; } }

  &[disabled] {
    border-color: var(--disabled-input-background);
    background-color: var(--disabled-input-background);
    input {
      color: #606060;
      -webkit-text-fill-color: #606060;
      opacity: 1;
      cursor: not-allowed; } }

  &-unsaved {
    border-color: var(--unsaved-border-color);
    background-color: var(--unsaved-background-color);
    --input-focus-border-color: var(--unsaved-border-color); }

  &-unsaved[disabled] {
    border-color: var(--unsaved-and-disabled-border-color);
    background-color: var(--unsaved-and-disabled-background-color); }

  .Alert {
    margin-bottom: 0px;
    background-color: white;
    padding: 5px 8px 0px 8px; } }
