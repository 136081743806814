$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BusinessAgreementsPage {
  &-BisaHelpButton {
    margin-left: 5px;
    p {
      color: var(--default-font-color);
      font-size: 14px; } }
  &-BobOrganizationList {
    margin-bottom: 20px; }
  &-OrganizationRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    &:focus, &:hover {
      background-color: var(--background-grey); }

    &-link {
      flex: 1 1;
      text-decoration: none;
      > span {
        display: flex;
        flex-direction: row;
        align-items: center; } }

    > .Button {
      margin: 10px;
      width: 100%;
      max-width: 100px;
      display: flex;
      justify-content: center;
      padding-right: 10px;
      padding-left: 10px; }

    &-nameAndBisaDate {
      margin-left: 10px; }

    &-name {
      flex: 1 1 100%;
      font-size: 1.1em;
      font-weight: 700;
      text-decoration: none;
      text-align: left; }

    &-bisaDate {
      font-style: italic;
      font-size: 12px;
      margin-top: 2px;
      text-align: left; } } }
