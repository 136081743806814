$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BobInviteEndUserForm {
  > .Form, > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    @include onScreensNarrowerThan('phone') {
      flex-direction: column; } }

  &-inputWithHelp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    @include onScreensNarrowerThan('phone') {
      margin-bottom: 10px; }
    input {
      min-width: 290px;
      max-width: 66vw; }
    .HelpButton {
      margin-right: 10px; }
    .TextInput {
      height: 35px; }
    .Dropdown {
      margin-right: 10px; } }

  &-buttons {
    .Button:not(:last-child) {
      margin-right: 10px; } }

  .TextInput {
    flex: 1 1;
    max-width: 700px;
    margin-right: 10px; }


  .HelpButton .TextArea {
    min-height: 420px; }

  .Spinner {
    margin-right: 5px; } }
