$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.IconButton {
  display: inline-block;
  > span {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0; }
  &:not(&-disabled):active {
    > span {
      top: 2px;
      left: 2px; } }

  &:focus > span > {
    .Icon:before {
      text-shadow: 0 0 2px var(--input-focus-border-color);
      text-stroke-width: 1px;
      text-stroke-color: var(--input-focus-border-color);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--input-focus-border-color); }
    .ContentBox {
      @include inputFocusBorder; } }

  &-bordered {
    margin: 1px;
    > span {
      @include contentBoxBorder;
      background: white; } }

  &-padded {
    > span {
      padding: 3px 7px; } } }
