$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BobSignupFromInviteForm {
  .Card-Header {
    display: block;
    text-align: center;
    border-bottom: 0; }
  &-InviteMessage {
    text-align: center;
    > p {
      margin-top: 0px;
      margin-bottom: 10px; }
    &-by {
      margin: 5px 0; } }
  &-bottomLinks {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 10px;
    .Link:last-child {
      text-decoration: none; } } }
