$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

// this class is designed to prevent scrolling of the window
// when we are currently displaying a modal
html.with-modal {
  overflow: hidden;
  touch-action: none; }

.Modal {
  @include shroud;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: auto;
  overscroll-behavior: contain;
  z-index: 3000;

  &-window {
    position: relative;
    margin: 20px;
    @include onScreensNarrowerThan('phone') {
      margin: 10px; }
    background-color: white;
    min-height: 100px;
    min-width: 100px;
    max-width: var(--main-content-width);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    padding: 20px; }

  &-closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    color: var(--error-red); } }
