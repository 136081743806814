$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-end;
  flex-direction: row;
  &[data-reverse] {
    flex-direction: row-reverse; }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5; }

  &-pseudo-checkbox {
    position: relative;
    top: -2px;
    display: inline-block;
    border: 1px solid #d8dce5;
    outline: 0;
    line-height: 1;
    vertical-align: middle;
    position: relative;
    border-radius: 2px;
    width: 14px;
    height: 14px;
    background-color: #fff;
    z-index: 1;
    @include inputBorder; }

  &-label {
    font-size: 14px;
    user-select: none;
    font-weight: 500;
    margin: 0 0 0 0.5em; }
  &-reverse &-label {
    margin: 0 0.5em 0 0; }

  > input {
    display: none; }

  &[data-checked] &-label {
    color: #409EFF; }

  &[data-checked] &-pseudo-checkbox {
    background-color: #409EFF;
    border-color: #409EFF;
    &:after {
      position: absolute;
      top: 1px;
      left: 4px;
      height: 8px;
      width: 4px;
      transform: rotate(45deg) scaleY(1);
      content: "";
      border: 1px solid white;
      border-left: 0;
      border-top: 0; } }

  &[data-unsaved] &-pseudo-checkbox {
    background-color: var(--unsaved-background-color);
    border-color: var(--unsaved-background-color); }

  &[data-unsaved][data-checked] &-pseudo-checkbox {
    background-color: var(--success-button-background-color);
    border-color: var(--success-button-background-color); }

  &[data-unsaved] &-label {
    color: green; } }
