$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Dropdown {
  width: fit-content;
  padding: 5px 20px 5px 10px;
  @include inputBorder;
  border-radius: 4px;
  color: #5a5e66;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=);
  background-position-y: center;
  background-position-x: calc(100% - 5px);
  background-repeat: no-repeat;
  background-color: white;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none; }
  &-fullWidth {
    width: 100%; }
  &-noIcon {
    background: none;
    padding-right: 5px; }
  &:focus {
    outline: none; }
  &-disabled {
    cursor: not-allowed;
    border-color: var(--disabled-input-background);
    background-color: var(--disabled-input-background);
    input {
      color: #606060;
      -webkit-text-fill-color: #606060;
      opacity: 1;
      cursor: not-allowed; } }
  &-unsaved {
    border-color: var(--unsaved-border-color);
    background-color: var(--unsaved-background-color); } }
