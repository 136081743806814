$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

// colors
$navy-blue: #204d8e;
$dark-navy-blue: #163F70;
$tru-blue: #177bdf;
$border-grey: rgb(209, 211, 221);
$boring-blue: #24394F;
$grey-text: #747474;
$greyed-out: #878C93;
$error-red: #f56c6c;
$background-grey: #EBEBEB;
$unsaved-background-color: #bedacc;
$unsaved-border-color: darken(#bedacc, 35%);
$unsaved-and-disabled-border-color: lighten($unsaved-border-color, 25%);
$unsaved-and-disabled-background-color: lighten($unsaved-background-color, 5%);
$saved-background-color: $navy-blue;
$disabled-input-background: #f5f7fa;

// button colors
$normal-button-text-color: #5a5e66;
$normal-button-background-color: #d3d3d3;
$primary-button-text-color: #fff;
$primary-button-background-color: $tru-blue;
$success-button-text-color: #fff;
$success-button-background-color:  #208d60;

$normal-button-background-color-lightened: desaturate(lighten($normal-button-background-color, 40%), 40%);
$primary-button-background-color-lightened: desaturate(lighten($primary-button-background-color, 40%), 40%);
$success-button-background-color-lightened: desaturate(lighten($success-button-background-color, 40%), 40%);

$normal-button-background-color-darkened: darken($normal-button-background-color, 5%);
$primary-button-background-color-darkened: darken($primary-button-background-color, 5%);
$success-button-background-color-darkened: darken($success-button-background-color, 5%);

$normal-button-hover-background-color: darken($normal-button-background-color, 3%);
$primary-button-hover-background-color: darken($primary-button-background-color, 5%);
$success-button-hover-background-color: darken($success-button-background-color, 5%);

$disabled-normal-button-text-color: transparentize($normal-button-text-color, 0.5);
$disabled-normal-button-background-color: lighten($normal-button-background-color, 10%);
$disabled-primary-button-text-color: transparentize($primary-button-text-color, 0.5);
$disabled-primary-button-background-color: transparentize($tru-blue, 0.5);
$disabled-success-button-text-color: transparentize($success-button-text-color, 0.5);
$disabled-success-button-background-color: #9ecab5;

$default-font-size: 14px;
$default-font: 500 #{default-font-size}/1.4 sans-serif;
$default-font-family: 'Avenir', Helvetica, Arial, sans-serif;
$default-font-color: #2c3e50;

$side-nav-width: 200px;
$side-nav-width-mobile: 275px;
$top-nav-height: 58px;
$page-column-width: 960px;
$side-nav-text-color: white;

$xl-header-font-size: 2em;
$lg-header-font-size: 1.602em;
$lg-header-font-weight: 500;
$md-header-font-size: 1.315em;
$md-header-font-weight: 500;
$sm-header-font-size: 0.702em;
$sm-header-font-weight: 500;
$xs-header-font-size: 0.702em;
$xs-header-font-weight: 400;

$collapse-animation-duration: 200ms;

// $content-box-margin: 10px
$vertical-padding: 20px;
$horizontal-padding: 10px;
$vertical-space: 15px;
$horizontal-space: 10px;
$content-box-margin: $horizontal-space;
$content-box-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.10);



.BPortalTheme {

  // colors
  --navy-blue: #{ $navy-blue };
  --navy-blue-darkened: #{ darken($navy-blue, 10%) };
  --dark-navy-blue: #{ $dark-navy-blue };
  --tru-blue: #{ $tru-blue };
  --border-grey: #{ $border-grey };
  --border-grey-lightened: #{ lighten($border-grey, 10%) };
  --border-grey-darkened: #{ darken($border-grey, 7%) };
  --boring-blue: #{ $boring-blue };
  --grey-text: #{ $grey-text };
  --greyed-out: #{ $greyed-out };
  --greyed-out-darkened: #{ darken($greyed-out, 7%) };
  --error-red: #{ $error-red };
  --background-grey: #{ $background-grey };
  --background-grey-darkened: #{ darken($background-grey, 7%) };
  --input-focus-border-color: var(--tru-blue);
  --unsaved-background-color: #{ $unsaved-background-color };
  --unsaved-border-color: #{ $unsaved-border-color };
  --unsaved-and-disabled-border-color: #{ $unsaved-and-disabled-border-color };
  --unsaved-and-disabled-background-color: #{ $unsaved-and-disabled-background-color };
  --unsaved-background-color-darkened: #{ darken($unsaved-background-color, 10%) };
  --saved-background-color: #{ $saved-background-color };
  --disabled-input-background: #{ $disabled-input-background };
  --disabled-input-background-dark: #{ darken($disabled-input-background, 5%) };
  --disabled-input-background-darker: #{ darken($disabled-input-background, 15%) };

  // button colors
  --normal-button-text-color: #{ $normal-button-text-color };
  --normal-button-background-color: #{ $normal-button-background-color };
  --primary-button-text-color: #{ $primary-button-text-color };
  --primary-button-background-color: #{ $primary-button-background-color };
  --success-button-text-color: #{ $success-button-text-color };
  --success-button-background-color: #{ $success-button-background-color };

  --normal-button-background-color-lightened: #{ $normal-button-background-color-lightened };
  --primary-button-background-color-lightened: #{ $primary-button-background-color-lightened };
  --success-button-background-color-lightened: #{ $success-button-background-color-lightened };

  --normal-button-background-color-darkened: #{ $normal-button-background-color-darkened };
  --primary-button-background-color-darkened: #{ $primary-button-background-color-darkened };
  --success-button-background-color-darkened: #{ $success-button-background-color-darkened };

  --normal-button-hover-background-color: #{ $normal-button-hover-background-color };
  --primary-button-hover-background-color: #{ $primary-button-hover-background-color };
  --success-button-hover-background-color: #{ $success-button-hover-background-color };

  --disabled-normal-button-text-color: #{ $disabled-normal-button-text-color };
  --disabled-normal-button-background-color: #{ $disabled-normal-button-background-color };
  --disabled-primary-button-text-color: #{ $disabled-primary-button-text-color };
  --disabled-primary-button-background-color: #{ $disabled-primary-button-background-color };
  --disabled-success-button-text-color: #{ $disabled-success-button-text-color };
  --disabled-success-button-background-color: #{ $disabled-success-button-background-color };

  --default-font-size: #{ $default-font-size };
  --default-font: #{ $default-font };
  --default-font-family: #{ $default-font-family };
  --default-font-color: #{ $default-font-color };

  --xl-header-font-size: #{ $xl-header-font-size };
  --lg-header-font-size: #{ $lg-header-font-size };
  --lg-header-font-weight: #{ $lg-header-font-weight };
  --md-header-font-size: #{ $md-header-font-size };
  --md-header-font-weight: #{ $md-header-font-weight };
  --sm-header-font-size: #{ $sm-header-font-size };
  --sm-header-font-weight: #{ $sm-header-font-weight };
  --xs-header-font-size: #{ $xs-header-font-size };
  --xs-header-font-weight: #{ $xs-header-font-weight };

  --collapse-animation-duration: #{ $collapse-animation-duration };

  --list-member-hover-background-color: var(--background-grey);
  --list-member-hover-background-color-dark: var(--dark-navy-blue);

  --link-blue: #0e7cd6;

  --content-box-margin: #{ $content-box-margin };

  // inheritable styles
  font: $default-font;
  font-family: $default-font-family;
  -moz-osx-font-smoothing: grayscale;
  color: $default-font-color;


  --vertical-padding: #{ $vertical-padding };
  --horizontal-padding: #{ $horizontal-padding };
  --vertical-space: #{ $vertical-space };
  --horizontal-space: #{ $horizontal-space };
  --content-box-margin: #{ $content-box-margin };
  --content-box-box-shadow: #{ $content-box-box-shadow };


  // TODO move these styles into the Layout component
  --top-nav-background: linear-gradient(to top, rgba(255,255,255,0), #8c8c8c 3px, white 3px, white);
  --side-nav-width: #{$side-nav-width};
  --side-nav-background: #24394F;
  --side-nav-background-button-hover: #314d6b;
  --side-nav-background-button-selected: #2c4661;
  --layout-maincontent-background: var(--background-grey);
  --side-nav-text-color: #{ $side-nav-text-color };
  @include onScreensNarrowerThan('tablet') {
    --top-nav-background: var(--boring-blue);
    --side-nav-background: #{ darken($boring-blue, 10%) };
    --side-nav-width: #{$side-nav-width-mobile}; } }
