$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Link {
  text-decoration: none;
  color: inherit;
  &, &:focus {
    outline: none; }

  &[disabled] {
    cursor: not-allowed; }
  &-subtle {
    color: var(--grey-text);
    &:hover {
      text-decoration: underline; } }
  &-text {
    color: var(--tru-blue); }
  &-link {
    color: var(--tru-blue);
    text-decoration: underline; }

  &-subtle, &-text, &-link {
    font-weight: 500;
    @include delayInputFocusBorder;
    &:focus {
      @include inputFocusBorder;
      text-decoration: underline; } } }

a.Link {
  cursor: pointer; }
