$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.CSVUploader {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include onScreensNarrowerThan('phone') {
    flex-direction: column; }

  .HelpButton {
    margin: 10px; }

  &-selectWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include onScreensNarrowerThan('phone') {
      margin-bottom: 15px; } }

  &-select {
    position: relative;
    border: 1px solid var(--border-grey);
    min-width: 291px;
    @include onScreensNarrowerThan('phone') {
      min-width: 200px; }
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    color: var(--greyed-out);
    white-space: nowrap;

    &:hover {
      border: 1px solid var(--border-grey); }
    .Icon {
      padding-right: 4px;
      padding-left: 6px;
      border-left: 1px solid var(--border-grey);
      height: 100%;
      display: flex;
      align-items: center; }
    input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      &::-webkit-file-upload-button {
        cursor: pointer; } } } }
