$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BuyingInterest {
  &-row {
    word-wrap: break-word;
    margin-top: 10px;
    &:not(:last-child) {
      padding-bottom: 10px; }
    ~ .BuyingInterest-row {
      margin-top: 0px; }
    .Header {
      display: inline-block;
      margin-right: 5px; } }
  &-tag {
    border: 1px solid #d1d3dd;
    border-radius: 10px;
    padding: 3px 6px;
    background-color: var(--background-grey);
    display: inline-block;
    margin: 5px;
    text-indent: initial; }
  &-buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    .Button {
      margin-left: 0.5em; } } }
