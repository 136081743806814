$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.HelpButton {
  color: inherit;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  line-height: 0;
  &-open {
    .HelpButton-Icon {
      color: #3a8ee6; }
    .HelpButton-bubble {
      display: block; } }
  > span .Icon:before {
    position: relative;
    top: 1px; } }
