$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BobsPersonalDataConfigurationForm {
  &-AddFieldForm {
    flex: 1 1;
    display: flex;
    flex-wrap: nowrap;
    margin-right: 30px;
    align-items: center;
    .DropdownPicker {
      margin-right: 10px;
      flex: 1 1; } }
  &-field {
    display: flex;
    margin: 0 -10px;
    padding: 5px 10px;
    &:hover {
      background-color: #f7f7f7; }
    > div {
     flex: 1 1; } }
  &-unsaved {
    background-color: var(--unsaved-background-color);
    &:hover {
      background-color: var(--unsaved-background-color-darkened); }
    > .Header {
      > i {
        font-size: 90%; } } }
  &-removing {
    > .Header {
      > span {
        text-decoration: line-through; } } } }
