$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BannerImage {
  padding-top: ($BANNER_HEIGHT / $BANNER_WIDTH) * 100%;
  height: 0;
  width: 100%;
  max-width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white; }
