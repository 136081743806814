$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.DropdownPicker {
  user-select: none;
  position: relative;
  @include inputBorder;
  height: 37px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:focus {
    outline: none; }

  &-disabled {
    border-color: #f5f7fa;
    cursor: not-allowed;
    background-color: #f5f7fa; }

  &-input {
    width: 100%;
    .TextInput {
      border: none; } }

  &-unsaved {
    border-color: var(--unsaved-border-color);
    background-color: var(--unsaved-background-color);
    &[disabled] .TextInput {
      border-color: var(--unsaved-and-disabled-border-color);
      background-color: var(--unsaved-and-disabled-background-color); } }

  > .TextInput {
   border: 0px solid transparent;
   &:focus, &:focus-within {
    box-shadow: none; } } }
