$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BobMailing {
  &-row {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include onScreensNarrowerThan('phablet') {
      justify-content: center;
      flex-direction: column; }
    &-header {
      color: var(--greyed-out); }
    & .Button {
      @include onScreensNarrowerThan('phablet') {
        margin-top: 15px; } } }
  .Dropdown-select {
    min-width: 210px; } }
