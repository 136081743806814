$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.SwitchList {
  &-Row {
    margin: 0 -10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    &:hover, &:focus-within {
      background-color: #f7f7f7; }
    > .Header {
      display: inline-block; }
    &-unsaved {
      background-color: var(--unsaved-background-color);
      &:hover {
        background-color: var(--unsaved-background-color-darkened); } } } }
