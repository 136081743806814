$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.PriceInput {
  &-singleCurrency {
    text-align: center;
    width: 100%; }
  .TextInputWithIcon-icon {
    padding: 0;
    @include onScreensNarrowerThan('phone') {
      flex: 0 0 27px; }
    .Dropdown {
      background: none;
      cursor: pointer;
      border: none;
      text-align-last: center; } } }
