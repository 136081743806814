$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.HelpAndFeedback {
  $page-padding: 5px;
  $open-button-size: 40px;
  $animation-duration: 200ms;
  $animation-easing: ease-in-out;
  $open-button-background-color: var(--tru-blue);

  z-index: 10000;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 100vh;
  max-width: 100vw;
  height: $open-button-size + ($page-padding * 2);
  width: $open-button-size + ($page-padding * 2);
  pointer-events: none;
  overscroll-behavior: contain;
  transition: bottom 200ms, background-color $animation-duration $animation-easing;

  &, &-closing {
    background-color: rgba(0,0,0,0); }
  &-closing {
    .HelpAndFeedback-container {
      background-color: white; } }

  // closed
  &-container {
    pointer-events: auto;
    right: $page-padding;
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 35px 0 0 35px;
    border-color: transparent var(--tru-blue);
    position: relative;
    transition: height $animation-duration $animation-easing, width $animation-duration $animation-easing, border-radius $animation-duration $animation-easing; }
  &-open &-container, &-opening &-container, &-closing &-container {
    border-width: 0; }

  &-opening, &-open, &-closing {
    height: 100vh;
    width: 100vw;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important; }
  &-opening, &-open {
    pointer-events: auto;
    background-color: rgba(0,0,0,0.5);
    @include onScreensNarrowerThan('phone') {
      max-height: 100%; }
    .HelpAndFeedback-container {
      max-height: calc(100vh - #{$page-padding * 2});
      max-width: calc(100vw - #{$page-padding * 2});
      height: 568px - ($page-padding * 2);
      width: 320px - ($page-padding * 2);
      border-radius: 5px;
      background-color: white;
      box-shadow: 0 12px 30px 0 rgba(0,0,0,.5), inset 0 1px 0 0 hsla(0,0%,100%,.65);
      @include onScreensNarrowerThan('phone') {
        max-height: 100%; } } }

  &-opening, &-closing {
    .HelpAndFeedback-OpenButton {
      display: none; } }

  &-OpenButton {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: white;
    position: absolute;
    bottom: 3px;
    right: 15px;
    > .Icon {
      display: block;
      position: relative;
      top: 1px; } }

  &-attachInfoHelpButton {
    margin-left: 5px; }

  .Form {
    overflow: auto;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    > .Form-ButtonRow {
      margin-bottom: 20px; } }

  .Form-Row, .Form-ButtonRow, .TextArea, .Alert {
    padding-top: 10px; }

  .TextArea, .TextInput {
    width: 100%; }

  .TextArea {
    flex: 1 1;
    min-height: 80px;
    > textarea {
      height: 100%; } }

  &-successAlert {
    flex: 1 1; } }
