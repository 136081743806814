$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BobLoginForm {
  .Card-Header {
    display: block;
    text-align: center;
    border-bottom: 0; }

  .Form-Item:not(:last-child) {
    margin-bottom: 20px; }

  .Alert {
    margin-bottom: 15px; }

  &-forgotPassword {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -5px; } }
