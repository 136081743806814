$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Table {
  display: block;
  overflow: auto;
  > table {
    border-collapse: collapse;
    width: auto;
    min-width: 100%;
    > tr {
      > th {
        border-bottom: 1px dotted var(--border-grey); }
      > td {
        font-family: monospace; }
      > th, > td {
        text-align: left;
        border-right: 1px dotted var(--border-grey);
        &:last-child {
          border-right: none; }
        padding: 5px 10px;
        white-space: nowrap; }
      &.Table-empty {
        border: none;
        > td {
          background-color: lightgrey;
          border: none; } } } }
  &-text {
    white-space: pre; } }
