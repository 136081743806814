$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.CropImage {
  position: relative;
  max-width: 100%;

  .reactEasyCrop_CropArea {
    box-shadow: none;
    border: none; }

  .reactEasyCrop_Container {
    background: white; }

  &-circle {
    .reactEasyCrop_Container {
      border-radius: 50%; } }

  &-buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out 0ms;
    > .Button {
      pointer-events: all;
      > span {
        display: block;
        width: 20px;
        height: 20px;
        text-align: center;
        margin-bottom: 5px; } } }

  &:hover &-buttons {
    opacity: 1; } }
