@import "./responsive-breakpoints.scss";

:root {
  @media only screen
  and (max-width: $RESPONSIVE_BREAKPOINT_PHONE)
  {
    --media-breakpoint-name: "phone";
  }

  @media only screen
  and (min-width: $RESPONSIVE_BREAKPOINT_PHONE + 1)
  and (max-width: $RESPONSIVE_BREAKPOINT_PHABLET)
  {
    --media-breakpoint-name: "phablet";
  }

  @media only screen
  and (min-width: $RESPONSIVE_BREAKPOINT_PHABLET + 1)
  and (max-width: $RESPONSIVE_BREAKPOINT_TABLET)
  {
    --media-breakpoint-name: "tablet";
  }

  @media only screen
  and (min-width: $RESPONSIVE_BREAKPOINT_TABLET + 1)
  {
    --media-breakpoint-name: "desktop";
  }
}

@each $breakpoint, $width in $breakpoints {
  @include onScreensNarrowerThan($breakpoint){
    .hide-below-#{$breakpoint}{
      display: none !important;
    }
  }
  @include onScreensWiderThan($breakpoint){
    .hide-above-#{$breakpoint}{
      display: none !important;
    }
  }
}
