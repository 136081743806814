$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationIcon {
  display: inline-block;
  max-width: initial;
  &, & > img {
    border-radius: 3px; }
  > img {
    display: block;
    width: 100%;
    height: 100%; }
  &-sm {
    height: 33px;
    width: 33px; }
  &-md {
    height: 50px;
    width: 50px; }
  &-lg {
    height: $ICON_HEIGHT;
    width: $ICON_WIDTH; }

  &-bordered {
    @include contentBoxBorder;
    background-color: white; } }

.OrganizationIcon-bordered.OrganizationIcon-sm {
  padding: 2px; }
.OrganizationIcon-bordered.OrganizationIcon-md {
  padding: 3px; }
.OrganizationIcon-bordered.OrganizationIcon-lg {
  padding: 5px; }
