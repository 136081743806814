$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.SetUpPage {
  > .Layout-maincontent > .TwoColumns {
    .TwoColumns-Column {
      &:first-child {
        padding: 20px 20px 20px 45px;
        border-right: 1px solid var(--border-grey);
        @include onScreensNarrowerThan('desktop') {
          padding: 20px;
          border: none; } }
      &:last-child {
        padding: 20px 45px 20px 20px;
        @include onScreensNarrowerThan('desktop') {
          padding: 20px; } } } }

  &-OrganizationProfile {
    &-topRow {
      .Header {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      &-buttons {
        > *:first-child {
          margin-right: 5px; } } } }
  &-SisaModal {
    width: 650px;
    white-space: pre-line;
    .Header-lg {
      @include onScreensNarrowerThan('phone') {
        font-size: 1.11em; } }
    &-textContent {
      white-space: pre-line;
      .Link {
        text-decoration: underline; } } } }
