$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

$gridColumns: 80px 120px 1fr 1fr 1fr 1fr;

.BobConsentReport {
  position: relative;
  padding-top: 5px;
  &-subHeaderRow {
    display: grid;
    grid-template-columns: $gridColumns;
    border-bottom: 1px solid var(--background-grey-darkened);
    .Header {
      color: var(--greyed-out);
      display: flex;
      align-items: center;
      &:last-child {
        justify-content: flex-end; } } }

  &-Report {
    &-topRow {
      display: grid;
      grid-template-columns: $gridColumns;
      height: 40px;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      &:hover {
        cursor: pointer;
        background-color: #f3f3f3; } }
    &-updatedHeader {
      margin-left: auto;
      &:hover {
        cursor: pointer; } }
    &-Consent {
      margin: 5px 10px;
      padding: 5px 5px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      border-radius: 3px;
      &:hover {
        background-color: #f8f8f8; }
      &-timeHeader {
        margin-left: auto;
        text-align: center; }
      & .Icon {
        &:hover {
          cursor: pointer; } }
      &-topRow {
        align-items: center;
        padding: 10px;
        &:hover {
          background-color: whitesmoke;
          cursor: pointer; } }
      &-text {
        color: var(--greyed-out);
        font-size: 13px; } } }
  &-chart {
    display: flex;
    justify-content: center;
    .chartjs-render-monitor {
      max-width: 800px;
      max-height: 800px; }
    &-hidden {
      display: none; } }
  &-toggleChart {
    position: absolute;
    top: 10px;
    right: 0px;
    @include onScreensNarrowerThan('tablet') {
      right: 20px; } } }
