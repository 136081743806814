$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BobSignupStripeForm {
  min-width: 350px;
  max-width: 450px;

  .Card-Header {
    display: block;
    text-align: center;
    border-bottom: 0; }

  .Card-Body {
    padding-top: 0; }

  .Alert {
    margin-bottom: 10px; }

  .Loading {
    transform: scale(.5);
    background-color: white; }

  &-paymentForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin-bottom: 20px; } }

  &-emailConfirmationMessage {
    margin-bottom: 15px; } }
